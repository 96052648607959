<template>
  <div>
    <b-row align-h="center" v-if="dataLoading || depLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-card v-else>
      <b-row>
        <b-col md="12">
          <h4 class="mb-1">Select Department</h4>
          <b-button
            class="mr-50"
            :variant="current == 0 ? 'primary' : 'outline-primary'"
            @click="depSelect(0)"
          >
            All
          </b-button>
          <b-button
            v-for="dep in deptOptions"
            :key="dep.id"
            class="mr-50"
            :variant="current == dep.id ? 'primary' : 'outline-primary'"
            @click="depSelect(dep.id)"
          >
            {{ dep.name }}
          </b-button>
        </b-col>
      </b-row>
      <h3 class="mt-2">Title</h3>
      <b-row>
        <b-col md="6">
          <!-- <b-form-group
            label="Title"
            invalid-feedback="Title is required."
            ref="title"
          >
        </b-form-group> -->
          <b-form-input
            id="mc-first-name"
            ref="title"
            placeholder="Enter title here"
            v-model="myObj.category.title"
          />
        </b-col>
      </b-row>
      <h3 class="mt-1 mb-0">Evaluation Points</h3>
      <b-row
        class="mt-1"
        v-for="(item, ind) in myObj.points"
        :key="ind"
        style="display: flex; align-items: center"
      >
        <b-col md="11" class="pr-0">
          <b-form-input
            id="mc-first-name"
            ref="details"
            placeholder="Enter point"
            v-model="myObj.points[ind].details"
          />
        </b-col>

        <b-col md="1">
          <b-button
            v-if="ind == myObj.points.length - 1"
            @click="AddPoint()"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="20" icon="PlusIcon" />
          </b-button>
          <b-button
            v-else
            @click="remove(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="20" icon="Trash2Icon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row align-h="end" class="mt-1">
        <b-col md="2">
          <b-button variant="success" block @click="save()" :disabled="request">
            <b-spinner v-if="request" small type="grow" />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BInputGroup,
    // vSelect,
    // flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  async created() {
    // console.log(this.$route.params.id);
    if (this.$route.params.id != 0) this.LoadData();
    this.LoadDep();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.details.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Expense",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      depLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "voucher", key: "voucherNumStr" },
        { label: "account", key: "account" },
        { label: "date", key: "date" },
        // { label: "details", key: "details" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 9,
        //   account: 90,
        //   date: "2023-01-10",
        //   details: "new",
        //   campusID: this.$store.state.userData.cId,
        // },
      ],
      deptOptions: [],
      selected: null,
      rangeDate: null,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      myObj: {
        category: {
          id: 0,
          departmentID: 0,
          title: "",
          campusID: this.$store.state.userData.cId,
        },
        points: [
          {
            id: 0,
            details: "",
            performanceCateoryID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ],
      },
      pointObj: {
        id: 0,
        details: "",
        performanceCateoryID: 0,
        campusID: this.$store.state.userData.cId,
      },
      current: 0,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    AddPoint() {
      this.myObj.points.push({
        id: 0,
        details: "",
        performanceCateoryID: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
    },
    async remove(item, ind) {
      if (item.id == 0) {
        this.myObj.points.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        // console.log("api");
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffPerformanceSheet/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.points.splice(ind, 1);
        // console.log(this.myObj);
      }
    },
    depSelect(id) {
      this.myObj.category.departmentID = id;
      this.current = id;
    },
    async save() {
      this.request = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "StaffPerformanceSheet/Save?db=" +
          this.$store.state.userData.db,
        body: this.myObj,
        message: "Performance sheet added successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.$router.go(-1);
      }
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffPerformanceSheet/LoadData?db=" +
          this.$store.state.userData.db +
          "&id=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("obj", this.myObj);
      this.current = this.myObj.category.departmentID;
      this.dataLoading = false;
    },
    async LoadDep() {
      this.depLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.deptOptions = await this.get(obj);
      // console.log("dept", this.deptOptions);
      this.depLoading = false;
    },
  },
};
</script>
